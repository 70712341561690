import React, { useState } from 'react';
import { connect } from 'react-redux';

import ApiManager from 'ApiManager';

import Button from 'components/Button';

import UserIcon from 'images/user-icon.svg';

import UserActions from 'reducers/userReducer';

const Api = new ApiManager();

const ResetPassword = () => {
    const [passwordState, setPasswordState] = useState('');
    const [requesting, setRequesting] = useState(false);

    const changePassword = () => {
        if (requesting === true) return null;
        if (passwordState.password !== passwordState.confirm) return null;
        if (passwordState.password === '') return null;

        setRequesting(true);

        Api.changePassword(passwordState.password)
            .then(() => {
                Api.getLoggedInUser()
                    .then((userResponse) => {
                        UserActions.logInUser(false, userResponse.data);
                        // ActorActions.setActor(userResponse.data.actor);
                    })
                    .catch((error) => {
                        console.log('ERROR [getLoggedInUser]', error);
                    });
            })
            .catch((error) => {
                console.log('ERROR [loginUser]', error);
            })
            .finally(() => {
                setRequesting(false);
            });

        return true;
    };

    return (
        <div className="page-reset-password-wrapper">
            <h3>
                <img src={UserIcon} alt="" /> Du må endre passord før du kan gå
                videre.
            </h3>

            <div className="reset-password-fields">
                <span>Passord</span>
                <input
                    type="password"
                    onChange={(e) =>
                        setPasswordState({
                            ...passwordState,
                            password: e.target.value,
                        })
                    }
                />
                <span>Bekreft passord</span>
                <input
                    type="password"
                    onChange={(e) =>
                        setPasswordState({
                            ...passwordState,
                            confirm: e.target.value,
                        })
                    }
                />
            </div>

            <Button primary onClick={changePassword}>
                Endre passord
            </Button>
        </div>
    );
};
const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...ownProps,
    };
};

ResetPassword.propTypes = {
    // user: PropTypes.object.isRequired,
};
export default connect(mapStateToProps)(ResetPassword);
